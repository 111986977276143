/// <reference path="o365.pwa.declaration.sw.CrudHandler.d.ts" />

import type { RecordStatus } from 'o365.pwa.types.ts';
import type { WhereExpression } from 'o365.pwa.modules.shared.dexie.WhereExpression.ts';
import type { ISortColumns, IGroupByAggregates, IGroupByColumns } from 'o365.pwa.declaration.sw.shared.DataObjectFieldUtilities.d.ts';
import type { IO365FlatOfflineDataRecord } from 'o365.pwa.declaration.sw.O365OfflineDataRecord.d.ts'
import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { Table, WhereClause, Collection } from 'o365.pwa.declaration.sw.dexie.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { DataObjectFieldUtilities } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.DataObjectFieldUtilities.d.ts')>('o365.pwa.modules.sw.DataObjectFieldUtilities.ts');
    const { groupBy } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.utilities.dataObject.groupBy.ts')>("o365.pwa.modules.sw.utilities.dataObject.groupBy.ts");
    const { Devex } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.Devex.d.ts')>("o365.pwa.modules.sw.Devex.ts");
    const { restructureRecordForOnlineDB } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.O365OfflineDataRecord.d.ts')>("o365.pwa.modules.sw.O365OfflineDataRecord.ts");
    const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts");

    const CrudHandler = {
        handleRetrieve: async (options: {
            appId: string;
            objectStoreId: string;
            fields: Array<{ name: string; }>
            distinctRows?: boolean;
            maxRecords?: number;
            skip?: number;
            filterObject?: any;
            whereObject?: any;
            masterDetailObject?: any;
            indexedDbWhereExpression?: WhereExpression;
            appIdOverride?: string;
            databaseIdOverride?: string;
            objectStoreIdOverride?: string;
        }, allowedStatuses: Array<RecordStatus> = [], disallowedStatuses: Array<RecordStatus> = ['DESTROYED', 'FILE-DESTROYED']): Promise<Array<object>> => {
            const dexieInstance = await CrudHandler.getDexieInstance({
                appId: options.appId,
                objectStoreId: options.objectStoreId,
                appIdOverride: options.appIdOverride,
                databaseIdOverride: options.databaseIdOverride,
                objectStoreIdOverride: options.objectStoreIdOverride
            });

            if (dexieInstance == null) {
                throw new Error('Failed to retrieve dexie instance');
            }

            let {
                maxRecords = -1,
                skip = 0,
                fields,
                filterObject,
                distinctRows,
                whereObject,
                masterDetailObject,
                indexedDbWhereExpression
            } = options;

            let dexieCollection: Table | WhereClause | Collection = dexieInstance;

            if (indexedDbWhereExpression) {
                dexieCollection = dexieCollection.where(indexedDbWhereExpression.keyPath);

                switch (indexedDbWhereExpression.type) {
                    case 'above':
                        dexieCollection = dexieCollection.above(indexedDbWhereExpression.lowerBound);
                        break;
                    case 'aboveOrEqual':
                        dexieCollection = dexieCollection.aboveOrEqual(indexedDbWhereExpression.lowerBound);
                        break;
                    case 'anyOf':
                        dexieCollection = dexieCollection.anyOf(indexedDbWhereExpression.keys);
                        break;
                    case 'anyOfIgnoreCase':
                        dexieCollection = dexieCollection.anyOfIgnoreCase(indexedDbWhereExpression.keys);
                        break;
                    case 'below':
                        dexieCollection = dexieCollection.below(indexedDbWhereExpression.upperBound);
                        break;
                    case 'belowOrEqual':
                        dexieCollection = dexieCollection.belowOrEqual(indexedDbWhereExpression.upperBound);
                        break;
                    case 'between':
                        dexieCollection = dexieCollection.between(
                            indexedDbWhereExpression.lowerBound,
                            indexedDbWhereExpression.upperBound,
                            indexedDbWhereExpression.includeLower ?? true,
                            indexedDbWhereExpression.includeUpper ?? false
                        );
                        break;
                    case 'equals':
                        dexieCollection = dexieCollection.equals(indexedDbWhereExpression.key);
                        break;
                    case 'equalsIgnoreCase':
                        dexieCollection = dexieCollection.equalsIgnoreCase(indexedDbWhereExpression.key);
                        break;
                    case 'inAnyRange':
                        dexieCollection = dexieCollection.inAnyRange(indexedDbWhereExpression.ranges, {
                            includeLowers: indexedDbWhereExpression.includeLowers ?? true,
                            includeUppers: indexedDbWhereExpression.includeUppers ?? false
                        });
                        break;
                    case 'noneOf':
                        dexieCollection = dexieCollection.noneOf(indexedDbWhereExpression.keys);
                        break;
                    case 'notEqual':
                        dexieCollection = dexieCollection.notEqual(indexedDbWhereExpression.key);
                        break;
                    case 'startsWith':
                        dexieCollection = dexieCollection.startsWith(indexedDbWhereExpression.prefix);
                        break;
                    case 'startsWithAnyOf':
                        dexieCollection = dexieCollection.startsWithAnyOf(indexedDbWhereExpression.prefixes);
                        break;
                    case 'startsWithIgnoreCase':
                        dexieCollection = dexieCollection.startsWithIgnoreCase(indexedDbWhereExpression.prefix);
                        break;
                    case 'startsWithAnyOfIgnoreCase':
                        dexieCollection = dexieCollection.startsWithAnyOfIgnoreCase(indexedDbWhereExpression.prefixes);
                        break;
                }
            }

            let data = await dexieCollection.toArray();

            Object.keys(data).forEach((key: any) => {
                if (key.endsWith("_JSON")) {
                    data[key] = JSON.stringify(data[key]);
                }
            });

            if (filterObject) {
                data = Devex.filter(filterObject, data);
            }

            if (whereObject) {
                data = Devex.filter(whereObject, data);
            }

            if (masterDetailObject) {
                data = Devex.filter(masterDetailObject, data);
            }

            if (allowedStatuses.length > 0 || disallowedStatuses.length > 0) {
                data = data.filter((item: any) => {
                    if (item.hasOwnProperty('O365_Status')) {
                        if (allowedStatuses.length > 0) {
                            return allowedStatuses.includes(item['O365_Status']);
                        } else if (disallowedStatuses.length > 0) {
                            return !disallowedStatuses.includes(item['O365_Status']);
                        } else {
                            return true
                        };
                    }

                    return true;
                });
            }

            const groupByColumns: Array<IGroupByColumns> = DataObjectFieldUtilities.getGroupByColumns(fields);
            const groupByAggregates: Array<IGroupByAggregates> = DataObjectFieldUtilities.getGroupByAggregates(fields);

            if (groupByColumns.length > 0) {
                data = groupBy(data, groupByColumns, groupByAggregates);
            }

            const columnsRemoved = new Set<string>();

            if (fields.length > 0) {
                const fieldNames = new Set(fields.map((field) => field.name));

                for (const record of data) {
                    for (const column of Object.keys(record)) {
                        if (fieldNames.has(column) || column.startsWith('O365_')) {
                            continue;
                        }

                        columnsRemoved.add(column);
                        delete record[column];
                    }
                }
            }

            if (distinctRows) {
                data = Array.from<string>(
                    new Set(data.map((obj: any) => JSON.stringify(obj)))
                ).map((str) => JSON.parse(str));
            }

            const sortColumns: Array<ISortColumns> = DataObjectFieldUtilities.getSortColumns(fields);

            if (sortColumns.length > 0) {
                const sortingArray = DataObjectFieldUtilities.getSortingArray(sortColumns);

                data = self.FastSort.sort(data).by(sortingArray);
            }

            return data.slice(skip, maxRecords > 0 ? (skip + maxRecords) : undefined);
        },

        handleRetrieveStream: (options: {
            appId: string;
            objectStoreId: string;
            filterObject?: any;
            whereObject?: any;
        }, allowedStatuses: Array<RecordStatus> = [], disallowedStatuses: Array<RecordStatus> = ['DESTROYED', 'FILE-DESTROYED']): ReadableStream => {
            let {
                appId,
                objectStoreId,
                filterObject,
                whereObject
            } = options;

            const stream = new ReadableStream({
                start: async (controller: ReadableStreamDefaultController) => {
                    try {
                        const dexieInstance = await CrudHandler.getDexieInstance({ appId, objectStoreId });

                        if (dexieInstance === null) {
                            throw new Error(`Failed to resolve Dexie instance - ${appId} + ${objectStoreId}`);
                        }

                        await dexieInstance.each((record: IO365FlatOfflineDataRecord, _cursor: any) => {
                            if (!record) {
                                return;
                            }

                            let data = [record];

                            if (filterObject) {
                                data = Devex.filter(filterObject, data);
                            }

                            if (whereObject) {
                                data = Devex.filter(whereObject, data);
                            }

                            data = data.filter(item => {
                                if (item.hasOwnProperty('O365_Status')) {
                                    if (allowedStatuses.length > 0) {
                                        return allowedStatuses.includes(item['O365_Status']);
                                    } else if (disallowedStatuses.length > 0) {
                                        return !disallowedStatuses.includes(item['O365_Status']);
                                    } else {
                                        return true
                                    };
                                }

                                return true;
                            });

                            if (data.length === 0) {
                                return;
                            }

                            const onlineDbReadyRecord = restructureRecordForOnlineDB(record);

                            controller.enqueue(onlineDbReadyRecord);
                        });
                    } catch (reason) {
                        // TODO: Implement
                    } finally {
                        controller.close();
                    }
                },
                pull: async (_controller: ReadableStreamDefaultController) => {
                    // TODO: Implement
                },
                cancel: async (_controller: ReadableStreamDefaultController) => {
                    // TODO: Implement
                },
            }, {
                size: (): number => {
                    return 4 * 1024 * 1024
                } // 4MiB
            });

            return stream;
        },

        handleRetrieveRowcount: async (options: {
            appId: string;
            dataObjectId: string;
            objectStoreIdOverride?: string;
            fields: Array<{ name: string; }>
            filterObject?: any;
            whereObject?: any;
            distinctRows?: boolean;
            indexedDbWhereExpression?: WhereExpression;
            skip?: number;
            maxRecords?: number;
        }, allowedStatuses: Array<RecordStatus> = [], disallowedStatuses: Array<RecordStatus> = ['DESTROYED', 'FILE-DESTROYED']): Promise<number> => {
            options.maxRecords = -1;
            options.skip = 0;
            const retrieveRecordsResponse = await CrudHandler.handleRetrieve({
                appId: options.appId,
                objectStoreId: options.dataObjectId,
                fields: options.fields,
                filterObject: options.filterObject,
                indexedDbWhereExpression: options.indexedDbWhereExpression,
                maxRecords: options.maxRecords,
                skip: options.skip,
                distinctRows: options.distinctRows,
                whereObject: options.whereObject,
                objectStoreIdOverride: options.objectStoreIdOverride,
            }, allowedStatuses, disallowedStatuses);

            return retrieveRecordsResponse.length;
        },

        handleCreate: async (options: {
            appId: string;
            dataObjectId: string;
            appIdOverride?: string;
            databaseIdOverride?: string;
            objectStoreIdOverride?: string;
            fields: Array<{ name: string; }>;
            providedRecord: IO365FlatOfflineDataRecord;
        }): Promise<IO365FlatOfflineDataRecord> => {
            Object.keys(options.providedRecord).forEach((key: any) => {
                if (key.endsWith("_JSON")) {
                    options.providedRecord[key] = JSON.stringify(options.providedRecord[key]);
                }
            });

            // Get IndexedDB handler based on body
            const dexieInstance = await CrudHandler.getDexieInstance({
                appId: options.appId,
                objectStoreId: options.dataObjectId,
                appIdOverride: options.appIdOverride,
                databaseIdOverride: options.databaseIdOverride,
                objectStoreIdOverride: options.objectStoreIdOverride,
            });

            if (dexieInstance === null) {
                throw new Error(`Failed to resolve Dexie instance - ${options.appId} + ${options.dataObjectId} + ${options.appIdOverride} + ${options.databaseIdOverride} + ${options.objectStoreIdOverride}`);
            }

            const appId = options.appIdOverride ?? options.appId;
            const databaseId = options.databaseIdOverride ?? "DEFAULT";
            const dataObjectId = options.objectStoreIdOverride ?? options.dataObjectId;

            const objectStoreRecord = await IndexedDBHandler.getObjectStore(appId, databaseId, dataObjectId);

            const userRecord = await IndexedDBHandler.getUser();

            const emptyRecord = (objectStoreRecord?.fields ?? []).reduce((accumulator: any, currentValue: string) => {
                accumulator[currentValue] = null;

                return accumulator;
            }, {});

            const isFileRecord = options.providedRecord.hasOwnProperty('FileRef') && !!options.providedRecord.FileRef;

            const record = Object.assign(emptyRecord,
                {
                    PrimKey: self.crypto.randomUUID(),
                    Created: (new Date()).toISOString(),
                    CreatedBy: userRecord?.userSession?.name ?? null,
                    CreatedBy_ID: userRecord?.id ?? null,
                    Updated: (new Date()).toISOString(),
                    UpdatedBy: userRecord?.userSession?.name ?? null,
                    UpdatedBy_ID: userRecord?.id ?? null,
                }, options.providedRecord, <IO365FlatOfflineDataRecord>{
                    O365_PrimKey: self.crypto.randomUUID(),
                    O365_Created: (new Date()).toISOString(),
                    O365_CreatedBy: userRecord?.userSession?.name ?? null,
                    O365_CreatedBy_ID: userRecord?.id ?? null,
                    O365_Updated: (new Date()).toISOString(),
                    O365_UpdatedBy: userRecord?.userSession?.name ?? null,
                    O365_UpdatedBy_ID: userRecord?.id ?? null,
                    O365_JsonDataVersion: objectStoreRecord?.jsonDataVersion ?? null,
                    O365_AppID: objectStoreRecord?.appId ?? null,
                    O365_Type: objectStoreRecord?.id ?? null,
                    O365_Status: isFileRecord ? 'FILE-CREATED' : 'CREATED',
                    O365_OriginalValues: '[]',
                    O365_UpdatedFields: '{}',
                    O365_ExternalRef: null
                }
            );

            if (record.FileRef) {
                record.FileUpdated = (new Date()).toISOString();
                // TODO: Add logic to set extension from filename
            }

            // TODO: Trigger before create trigger

            // Create record in IndexedDB
            await dexieInstance.put(record);

            // TODO: Trigger after create trigger

            // Retrieve record from IndexedDB
            const createdRecord = await dexieInstance.get(record.PrimKey as string) as IO365FlatOfflineDataRecord;

            // Return created record
            return createdRecord;
        },

        handleBulkCreate: async (_options: any): Promise<void> => {
            throw new Error('Not implemented');
        },

        handleFileCreate: async (_options: any): Promise<void> => {
            throw new Error('Not implemented');
        },

        /**
         * This function handles the update of a specified data object in an IndexedDB database.
         * It first retrieves the current record from the database using the primary key provided in the input record.
         * If the record is found, it updates the record's metadata with the current user's information and the current time.
         * The function also determines if the file associated with the record has changed and updates the record's status accordingly.
         * It also tracks which fields have been updated by comparing the provided record with the current record.
         * Finally, it updates the record in the database and retrieves the updated record to return it.
         * 
         * @async
         * @param {Object} options - The options object.
         * @param {string} options.appId - The application ID.
         * @param {string} options.dataObjectId - The data object ID.
         * @param {string} [options.appIdOverride] - The overridden application ID, if any.
         * @param {string} [options.databaseIdOverride] - The overridden database ID, if any.
         * @param {string} [options.objectStoreIdOverride] - The overridden object store ID, if any.
         * @param {IO365FlatOfflineDataRecord} options.providedRecord - The record to be updated.
         * @returns {Promise<IO365FlatOfflineDataRecord>} - The updated record.
         * @throws Will throw an error if the record matching the primary key is not found.
         */
        handleUpdate: async (options: {
            appId: string;
            dataObjectId: string;
            appIdOverride?: string;
            databaseIdOverride?: string;
            objectStoreIdOverride?: string;
            providedRecord: IO365FlatOfflineDataRecord
        }): Promise<IO365FlatOfflineDataRecord> => {
            // Get an instance of the Dexie database
            const dexieInstance = await CrudHandler.getDexieInstance({
                appId: options.appId,
                objectStoreId: options.dataObjectId,
                appIdOverride: options.appIdOverride,
                databaseIdOverride: options.databaseIdOverride,
                objectStoreIdOverride: options.objectStoreIdOverride
            });

            if (dexieInstance === null) {
                throw new Error(`Failed to resolve Dexie instance - ${options.appId} + ${options.dataObjectId} + ${options.appIdOverride} + ${options.databaseIdOverride} + ${options.objectStoreIdOverride}`);
            }

            // Extract the provided record from the options
            const providedRecord = options.providedRecord;

            Object.keys(providedRecord).forEach((key: any) => {
                if (key.endsWith("_JSON")) {
                    providedRecord[key] = JSON.stringify(providedRecord[key]);
                }
            });

            // Extract the primary key from the provided record
            const providedPrimKey = providedRecord.PrimKey;

            if (typeof providedPrimKey !== 'string') {
                throw new Error('Failed to validate the provided PrimKey as a valid string');
            }

            // Retrieve record based on primkey
            const currentRecord = await dexieInstance.get(providedPrimKey);

            // If the current record is not found, throw an error
            if (currentRecord === undefined) {
                throw new Error(`Unable to find record matching the PrimKey: ${providedPrimKey}`);
            }

            // Retrieve the current user's record
            const userRecord = await IndexedDBHandler.getUser();

            // Extract the user ID and name from the user record, defaulting to null if not found
            const userId = userRecord?.id ?? null;
            const userName = userRecord?.userSession?.name ?? null;

            // Update the metadata fields of the provided record
            providedRecord.UpdatedBy_ID = userId;
            providedRecord.O365_UpdatedBy_ID = userId;
            providedRecord.UpdatedBy = userName;
            providedRecord.O365_UpdatedBy = userName;
            providedRecord.O365_Updated = (new Date()).toISOString();
            providedRecord.Updated = (new Date()).toISOString();

            // Determine if the file reference has changed
            const fileChanged = (currentRecord.FileRef ?? null) !== (providedRecord.FileRef ?? null);

            if (fileChanged) {
                currentRecord.FileUpdated = (new Date()).toISOString();
            }

            // Merge the provided record with the current record
            const record: IO365FlatOfflineDataRecord = Object.assign({}, currentRecord, providedRecord);

            // If the provided record does not have a status, update the status based current status
            if (!providedRecord.hasOwnProperty("O365_Status")) {
                // Update field: Status
                switch (record.O365_Status) {
                    case 'UNSYNCED':
                        throw Error('Invalid state. Could not update unsynced record');
                    case 'SYNCED':
                    case 'FILE-SYNCED':
                    case 'UPDATED':
                        record.O365_Status = fileChanged ? 'FILE-UPDATED' : 'UPDATED';
                        break;
                    case 'CREATED':
                        record.O365_Status = fileChanged ? 'FILE-CREATED' : 'CREATED';
                        break;
                    case 'DRAFT':
                        record.O365_Status = fileChanged ? 'FILE-DRAFT' : 'DRAFT';
                        break;
                    case 'FILE-DRAFT':
                    case 'FILE-CREATED':
                    case 'FILE-UPDATED':
                    case 'DESTROYED':
                    case 'FILE-DESTROYED':
                        break
                }
            }

            // If the record status is 'UPDATED' or 'FILE-UPDATED', track the updated fields
            if (['UPDATED', 'FILE-UPDATED'].includes(record.O365_Status)) {
                const providedRecordWithoutPrimKey = Object.assign({}, providedRecord);

                const originalValues: { [key: string]: any } = JSON.parse(record.O365_OriginalValues ?? '{}');
                const updatedFields = new Set<string>(JSON.parse(record.O365_UpdatedFields ?? '[]'));

                for (const key of Object.keys(providedRecordWithoutPrimKey)) {
                    if (key.startsWith('O365_') || key === 'PrimKey') {
                        delete providedRecordWithoutPrimKey[key];
                        continue;
                    }

                    const newValue = providedRecordWithoutPrimKey[key];

                    if (updatedFields.has(key)) {
                        const originalValue = originalValues[key];

                        if (originalValue === newValue) {
                            delete originalValues[key];
                            updatedFields.delete(key);
                        }
                    } else {
                        const currentValue = currentRecord[key];

                        if (currentValue !== newValue) {
                            originalValues[key] = currentValue ?? null;
                            updatedFields.add(key);
                        }
                    }
                }

                if (!checkSetAgainstArrayOptimized(updatedFields, ['Updated', 'UpdatedBy'])) {
                    Object.assign(record, originalValues);

                    record.O365_Status = record.FileRef ? 'FILE-SYNCED' : 'SYNCED';

                    record.O365_OriginalValues = '{}';
                    record.O365_UpdatedFields = '[]';
                } else {
                    record.O365_OriginalValues = JSON.stringify(originalValues);
                    record.O365_UpdatedFields = JSON.stringify(Array.from(updatedFields));
                }
            }

            // TODO: Trigger before update trigger

            // Update record in indexdb
            await dexieInstance.put(record);

            // TODO: Trigger after update trigger

            const updatedPrimKey = record.PrimKey;

            if (typeof updatedPrimKey !== 'string') {
                throw new Error('Failed to validate the updated PrimKey as a valid string');
            }

            // Retrieve updated record from indexdb
            const updatedRecord = await dexieInstance.get(updatedPrimKey);

            // Return updated record
            return updatedRecord;
        },

        handleBulkUpdate: async (_options: any): Promise<void> => {
            throw new Error('Not implemented');
        },

        handleUpdateFile: async (_options: any): Promise<void> => {
            throw new Error('Not implemented');
        },

        handleDestroy: async (options: {
            appId: string;
            dataObjectId: string;
            appIdOverride?: string;
            databaseIdOverride?: string;
            objectStoreIdOverride?: string;
            providedRecord: IO365FlatOfflineDataRecord
        }): Promise<boolean> => {
            const dexieInstance = await CrudHandler.getDexieInstance({
                appId: options.appId,
                objectStoreId: options.dataObjectId,
                appIdOverride: options.appIdOverride,
                databaseIdOverride: options.databaseIdOverride,
                objectStoreIdOverride: options.objectStoreIdOverride
            });

            if (dexieInstance === null) {
                throw new Error(`Failed to resolve Dexie instance - ${options.appId} + ${options.dataObjectId} + ${options.appIdOverride} + ${options.databaseIdOverride} + ${options.objectStoreIdOverride}`);
            }

            const providedPrimKey = options.providedRecord.PrimKey;

            if (typeof providedPrimKey !== 'string') {
                throw new Error('Failed to validate provided PrimKey as a valid string');
            }

            // Retrieve record based on primkey
            const indexedDBRecord = await dexieInstance.get(providedPrimKey);

            if (indexedDBRecord === undefined) {
                return false;
            }

            // Merge indexdb record with changes from request body
            const record: IO365FlatOfflineDataRecord = Object.assign(indexedDBRecord, options.providedRecord);

            let newStatus: RecordStatus | null = null;

            switch (record.O365_Status) {
                case 'UNSYNCED':
                    throw Error(`Invalid state. Could not destroy ${record.O365_Status} record`);
                case 'SYNCED':
                case 'UPDATED':
                    newStatus = record.hasOwnProperty('FileRef') ? 'FILE-DESTROYED' : 'DESTROYED';
                    break;
                case 'FILE-SYNCED':
                case 'FILE-UPDATED':
                    newStatus = 'FILE-DESTROYED';
                    break;
                case 'FILE-DRAFT':
                case 'FILE-CREATED':
                    const fileStoreRecord = await IndexedDBHandler.retrieveFileStoreRecord(record.FileRef as string);
                    if (fileStoreRecord) {
                        const pdfRecord = fileStoreRecord.pdfRef ? await IndexedDBHandler.retrieveFileStoreRecord(fileStoreRecord.pdfRef) : null;
                        const thumbnailRecord = fileStoreRecord.thumbnailRef ? await IndexedDBHandler.retrieveFileStoreRecord(fileStoreRecord.thumbnailRef) : null;

                        if (pdfRecord) await IndexedDBHandler.deleteFileStoreRecord(pdfRecord);
                        if (thumbnailRecord) await IndexedDBHandler.deleteFileStoreRecord(thumbnailRecord);

                        await IndexedDBHandler.deleteFileStoreRecord(fileStoreRecord);
                    }
                    break;
                case 'DRAFT':
                case 'CREATED':
                case 'DESTROYED':
                case 'FILE-DESTROYED':
                    break
            }

            if (newStatus === null) {
                await dexieInstance.delete(record.PrimKey as string);
            } else {
                await dexieInstance.put({ ...record, O365_Status: newStatus, O365_UpdatedFields: "{}", O365_OriginalValues: "[]" });
            }

            return true;
        },

        handleBulkDestroy: async (_options: any): Promise<void> => {
            throw new Error('Not implemented');
        },

        handleDestroyFile: async (_options: any): Promise<void> => {
            throw new Error('Not implemented');
        },

        getDexieInstance: async (options: {
            appId: string;
            objectStoreId: string;
            appIdOverride?: string;
            databaseIdOverride?: string;
            objectStoreIdOverride?: string;
        }): Promise<Table | null> => {
            const appId = options.appIdOverride ?? options.appId;
            const databaseId = options.databaseIdOverride ?? 'DEFAULT';
            const objectStoreId = options.objectStoreIdOverride ?? options.objectStoreId;

            const dexieInstance = await IndexedDBHandler.getDexieInstance(appId, databaseId, objectStoreId);

            return dexieInstance;
        }
    }

    function checkSetAgainstArrayOptimized<T>(setToCheck: Set<T>, array: Array<T>) {
        const arraySet = new Set(array);

        for (const value of setToCheck) {
            if (!arraySet.has(value)) {
                return true;
            }
        }

        return false;
    }

    self.o365.exportScripts({ CrudHandler });
})();

